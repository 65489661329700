<template>
    <div class="contain" :style="$common.mobile() ? 'margin-top: 50px;' : ''">
        <div class="title-sub">{{ formalItem.name }} {{ formalItem.name ? '>' : '' }} {{ childItem.name }}</div>
        <div class="content-detail">
            <div class="fl_c al_c">
                <div class="contentTitle" :style="$common.mobile() ? 'font-size: 25px' : ''">
                    {{ articleDetail.title || PreSellDetail.presellTitle }}
                </div>
                <div :class="['contentTime', $common.mobile() ? 'fl_c' : '']">
                    <div class="time-list" :style="$common.mobile() ? 'font-size: 15px' : ''">
                        {{ $t('detailPage.releaseTime') }}: {{ articleDetail.showTime || PreSellDetail.publishDate }}
                    </div>
                    <div class="time-list" :style="$common.mobile() ? 'font-size: 15px' : ''">
                        {{ $t('detailPage.source') }}: {{ articleDetail.source ? articleDetail.source :
                        $t('detailPage.none') }}
                    </div>
                </div>
                <div v-if="!$route.query.isPre" class="contentSty" v-html="articleDetail.content"></div>
                <div class="fl_c al_c" v-else>
                    <div class="red-bag">
                        <div class="name">{{ $t('detailPage.productInfo') }}</div>
                    </div>
                    <table class="borderless-table">
                        <tr>
                            <td>{{ $t('detailPage.contractProductCode') }}</td>
                            <td>{{ $t('detailPage.contractProductName') }}</td>
                            <td>{{ $t('detailPage.specification') }}</td>
                            <td>{{ $t('detailPage.price') }}</td>
                            <td>{{ $t('detailPage.totalAmount') }}</td>
                            <td>{{ $t('detailPage.subscriptionDepositRatio') }}</td>
                        </tr>
                        <tr style="color:#6F6F6F ">
                            <td>{{ PreSellDetail.symbol }}</td>
                            <td>{{ PreSellDetail.name }}</td>
                            <td>{{ PreSellDetail.unitSpecification }}</td>
                            <td>{{ PreSellDetail.price }}</td>
                            <td>{{ PreSellDetail.totoalNumber }}</td>
                            <td>{{ PreSellDetail.bookMarginRatio }}</td>
                        </tr>
                    </table>
                    <div class="line"></div>
                    <div class="fl_r ju_b">
                        <div class="title-red">{{ $t('detailPage.breachLiability') }}:</div>
                        <div class="backtrackDesc" v-html="PreSellDetail.backtrackDesc"></div>
                    </div>
                    <div class="line"></div>
                    <div class="fl_r ju_b" style="width: 70%;margin-bottom:40px ">
                        <div class="fl_r">
                            <img src="../../assets/img/3.png" alt="" class="icontime"/>
                            <div class="time-title">{{ $t('detailPage.preSaleTime') }}:</div>
                            <div class="time-value">{{ PreSellDetail.presellDate }}</div>
                        </div>
                        <div class="fl_r">
                            <img src="../../assets/img/2.png" alt="" class="icontime"/>
                            <div class="time-title">{{ $t('detailPage.deliveryDate') }}:</div>
                            <div class="time-value">{{ PreSellDetail.deliveryDate }}</div>
                        </div>
                    </div>
                    <div class="red-bag">
                        <div class="name">{{ $t('detailPage.transactionInfo') }}</div>
                    </div>
                    <div class="fl_r" style="width: 90%;margin-top: 50px">
                        <div class="box fl_r ju_c">
                            <div class="bottom-title">{{ $t('detailPage.contractFeeType') }}:</div>
                            <div class="bottom-value">{{ PreSellDetail.feeBuyType }}</div>
                        </div>
                        <div class="box fl_r ju_c">
                            <div class="bottom-title">{{ $t('detailPage.transferFeeType') }}:</div>
                            <div class="bottom-value">{{ PreSellDetail.feeSellType }}</div>
                        </div>
                        <div class="box fl_r ju_c">
                            <div class="bottom-title">{{ $t('detailPage.depositType') }}:</div>
                            <div class="bottom-value">{{ PreSellDetail.marginType }}</div>
                        </div>
                    </div>
                    <div class="fl_r" style="width: 90%;margin-top: 50px">
                        <div class="box fl_r ju_c">
                            <div class="bottom-title">{{ $t('detailPage.contractServiceFeeAmount') }}:</div>
                            <div class="bottom-value">{{ PreSellDetail.feeBuyAmount }}</div>
                        </div>
                        <div class="box fl_r ju_c">
                            <div class="bottom-title">{{ $t('detailPage.transferServiceFeeAmount') }}:</div>
                            <div class="bottom-value">{{ PreSellDetail.feeSellAmount }}</div>
                        </div>
                        <div class="box fl_r ju_c">
                            <div class="bottom-title">{{ $t('detailPage.depositAmount') }}:</div>
                            <div class="bottom-value">{{ PreSellDetail.marginAmount }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import {getarticleDetail, getarticlesInfo, getPreSellDetail} from "../../utils/api/index.js";

    export default {
        name: "detailPage",
        components: {},
        data() {
            return {
                articleId: "",
                columnId: "",
                articleDetail: {},
                PreSellDetail: {},
            };
        },
        computed: {
            formalItem: {
                get() {
                    return this.$store.state.formalItem;
                },
                set(val) {
                },
            },
            childItem: {
                get() {
                    return this.$store.state.childItem;
                },
                set(val) {
                },
            },
        },
        //   watch: {
        //     formalItem: {
        //       handler: function (newValue, oldValue) {
        //         console.log("----formalItem", newValue);
        //         if (newValue.subArticles.length > 0) {
        //           //   this.activeId = newValue.subArticles[0].id;
        //         }
        //       },
        //       immediate: true,
        //     },
        //     childItem(newValue, oldValue) {
        //       console.log("----childItem", newValue);
        //       if (newValue) {
        //         // this.getarticlesInfo(newValue.id);
        //       }
        //     },
        //   },
        mounted() {
            this.$store.commit("setWeixing", false);
            window.scrollTo(0, 0);
            this.articleId = this.$route.query.articleId;
            this.columnId = this.$route.query.columnId;
            if (this.$route.query.isPre) {
                this.getPreSellDetail(this.articleId)
            } else {
                this.getarticleDetail(this.$route.query.articleId);
            }
        },
        methods: {
            async getPreSellDetail(id) {
                let res = await getPreSellDetail({
                    id
                });
                this.PreSellDetail = res.data;
                console.log(this.PreSellDetail, 'PreSellDetailPreSellDetail')
            },
            async getarticleDetail(id) {
                let res = await getarticleDetail({
                    articleId: id,
                });
                this.articleDetail = res.data;
                // console.log("----articleDetail", this.articleDetail);
            },
        },
    };
</script>

<style scoped lang="scss">
    .fl_r {
        display: flex;
        flex-direction: row;
    }

    .fl-d {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .fr-jub {
        justify-content: space-between;
    }

    .contain {
        width: 100%;
        // width: 100vw;
        margin: 0 auto;
        margin-top: 440px;
        height: auto;

        .title-sub {
            background-color: #850416;
            height: 70px;
            line-height: 70px;
            color: #fff;
            font-size: 22px;
            font-weight: 400;
            padding-left: 169px;
            box-sizing: border-box;
        }

        .content-detail {
            min-height: 800px;
            padding: 64px 0;
            box-sizing: border-box;
            width: 70%;
            margin: 0 auto;

            .contentTitle {
                font-weight: 500;
                color: #212121;
                font-family: "PingFang SC";
                font-size: 40px;
            }

            .contentTime {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                line-height: 50px;
                height: 50px;
                border-bottom: 2px solid #b70c04;
                text-align: center;
                margin: 30px 0;
                padding: 0 40px;
                box-sizing: border-box;
                color: #757575;
                font-family: "PingFang SC";
                font-size: 20px;
                font-weight: 400;
            }

            .contentImg {
                width: 100%;
                height: 100%;
            }

            .contentSty {
                margin-top: 20px;
            }
        }
    }

    .red-bag {
        width: 1339px;
        height: 50px;
        background: #F1EBEB;
        border-radius: 0px 0px 0px 0px;
        padding-left: 40px;

        .name {
            width: auto;
            height: 50px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 20px;
            color: #8D3743;
            line-height: 50px;
            font-style: normal;
            text-transform: none;
        }
    }

    .borderless-table {
        width: 100%;
        margin: 30px 0 0;
        text-align: center;
        border-collapse: collapse;
    }

    .borderless-table td {
        width: 88px;
        padding: 22px;
        border: none;
    }

    .line {
        width: 100%;
        height: 2px;
        margin: 30px;
        background: #EAEAEA;
    }

    .title-red {
        width: auto;
        height: 28px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 20px;
        color: #850416;
        line-height: 23px;
        text-align: right;
        font-style: normal;
        text-transform: none;
        margin: 0 39px 0 46px;
        white-space: nowrap;
    }

    .backtrackDesc {
        width: 90%;
    }

    .icontime {
        width: 28px;
        height: 28px;
    }

    .time-title {
        width: auto;
        height: 28px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #000000;
        line-height: 28px;
        text-align: right;
        font-style: normal;
        text-transform: none;
        margin: 0 12px 0 15px;
    }

    .time-value {
        width: auto;
        height: 28px;
        font-family: DINPro, DINPro;
        font-weight: 400;
        font-size: 18px;
        color: #6F6F6F;
        line-height: 28px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .box {
        width: 33.33%;
        text-align: center;

        .bottom-title {
            width: auto;
            height: 25px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 18px;
            color: #000000;
            line-height: 21px;
            font-style: normal;
            text-transform: none;
            text-align: center;
            margin-right: 10 rpx;
        }

        .bottom-value {
            width: 53px;
            height: 25px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 18px;
            color: #6F6F6F;
            line-height: 21px;
            text-align: left;
            font-style: normal;
            text-transform: none;
        }
    }
</style>
